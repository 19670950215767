jQuery(document).ready(function($) {
	/* Mobile Menu */
	mobileNavigationMenuSelector = ".mobile-navigation-menu"
	menu = new Mmenu(mobileNavigationMenuSelector, {
		extensions: [
			"position-back",
			"position-top",
		],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true,
		}
	}, {
		fixedElements: {
			elemInsertMethod: "prependTo"
		}
	});

	api = menu.API;
	$(".mobile-menu-button").click(function() {
		if ($(mobileNavigationMenuSelector).hasClass("mm-menu_opened")) {
			api.close();
		}
		else {
			api.open();
		}
	});

	/* Accessible menu */
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".hasChildren > .dropdown-toggle").click(function(event) {
			if (!$(this).parents(".hasChildren").hasClass("open")) {
				$(".nav-wrapper").removeClass("open");
				event.preventDefault();
				$(this).parent(".hasChildren").addClass("open");
			}
		});
	}
	else {
		$(".nav-wrapper").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}
	$(".accesible-navigation-menu").accessibleMenu();

	$(".accesible-navigation-menu .dropdown-toggle").each(function() {
		if ($(this).hasClass("no-link")) {
			$(this).removeAttr("href");
		}
	});

	/* Sticky Header */
	addStickyHeader();
	$(window).scroll(function() {
		addStickyHeader();
	});

	$( ".search-icon" ).click(function() {
		$( ".search-content-form" ).slideToggle( "fast", function() {
			if ( $(".search-content-form").is(":visible") ) {
				$( ".search-content-form" ).css('opacity', '1');
			}
			else {
				$( ".search-content-form" ).css('opacity', '0');
			}
		});
	});

	// More In This Section Menu
	$(".mini-menu-dropdown-toggle").click(function() {
		$( ".mini-menu-dropdown-list" ).slideToggle();
	});

	// load locations items
	showLocationItems();
	$(window).scroll(function() {
		showLocationItems()
	});

	function addStickyHeader() {
		if ($(window).scrollTop() > 1) {
			$('.navbar-wrapper').addClass('sticky');
		}
		else {
			$('.navbar-wrapper').removeClass('sticky');
		}
	}

	function showLocationItems() {
		$('#locations_module.list .locations-list-items .list-item').each(function() {
			var bottom_of_object = $(this).offset().top + ($(this).outerHeight() * 0.5);
			var bottom_of_window = $(window).scrollTop() + $(window).height();
			if (bottom_of_window > bottom_of_object) {
				$(this).addClass('active');
			}
		});
	}

	$( "span.primary" ).parent().css( "border-bottom", "0" );
	$( "span.secondary" ).parent().css( "border-bottom", "0" );
	$( "span.tertiary" ).parent().css( "border-bottom", "0" );
});


